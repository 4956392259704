<template>
  <div>
    <b-form class="form" @submit.stop.prevent="submit">
      <div
        id="kt_subheader"
        class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
      >
        <div
          class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        >
          <!--begin::Info-->
          <div class="d-flex align-items-center flex-wrap mr-2">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
              Item Management
            </h5>
            <!--end::Page Title-->
            <!--begin::Action-->
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>

            <span class="text-muted font-weight-bold mr-4"> </span>
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>

            <!--end::Action-->
          </div>

          <div class="d-flex align-items-center"></div>
          <!--end::Info-->
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom bg-alert">
            <v-parallax height="100" dark src="/media/header-green.jpg">
              <div class="display-4 font-weight-thin mt-4">
                <strong> {{ name }} </strong>
              </div>
              <v-row align="center" justify="center">
                <v-col class="text-right" cols="12"> </v-col>
              </v-row>
            </v-parallax>

            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class=" row">
                    <label class="col-5 "> <Strong>Product </Strong></label>
                    <div class="col-7">
                      {{ product_name }}
                    </div>
                  </div>
                  <div class=" row">
                    <label class="col-5 ">
                      <Strong>Attribute Set </Strong></label
                    >
                    <div class="col-7">
                      {{ attribute_set }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class=" row">
                    <label class="col-5 ">
                      <Strong>Date Created </Strong></label
                    >
                    <div class="col-7">
                      {{ created_at | date_format }}
                    </div>
                  </div>
                  <div class=" row">
                    <label class="col-5 "> <Strong>Global Id </Strong></label>
                    <div class="col-7">
                      {{ $route.params.id }}
                    </div>
                  </div>
                </div>
              </div>

              <v-spacer> </v-spacer>
              <div class="form-group row">
                <label class="col-5 col-form-label">Status</label>
                <div class="col-7">
                  {{ status }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label">Name</label>
                <div class="col-7">
                  {{ name }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label">SKU #</label>
                <div class="col-7">
                  {{ sku_id }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label">UPC</label>
                <div class="col-7">
                  {{ upc }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-5 col-form-label">UPC 2</label>
                <div class="col-7">
                  {{ upc2 }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label">ISBN</label>
                <div class="col-7">
                  {{ isbn }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <v-card>
            <v-tabs horizontal>
              <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Main Image
              </v-tab>
              <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Additional Images
              </v-tab>
              <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Video Links
              </v-tab>

              <v-tab-item>
                <v-card class="mx-auto h-100">
                  <img
                    id="image_cover"
                    style="width: 100%; max-height: 480px;"
                    :src="
                      main_image_url != ''
                        ? baseurl + main_image_url
                        : '/media/product-placeholder.jpg'
                    "
                  />

                  <v-card-actions>
                    <v-btn text>Main Image</v-btn>
                    {{ main_image_name }}

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-row>
                    <v-col
                      v-for="(img, index) in additional_images"
                      :key="index"
                      cols="6"
                      sm="4"
                    >
                      <v-img
                        :src="
                          img.url != ''
                            ? baseurl + img.url
                            : 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                        "
                      >
                        <div class="fill-height repeating-gradient"></div>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div class="form-group row">
                      <label class="col-3 col-form-label text-dark"
                        >Youtube Link</label
                      >
                      <div class="col-7">
                        {{ youtube }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3 col-form-label text-dark"
                        >Vimeo Link</label
                      >
                      <div class="col-7">
                        {{ vimeo }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Secondary Name</label>
                    <div class="col-7">
                      {{ price }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">URL Key</label>
                    <div class="col-7">
                      {{ url }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">UOM</label>
                    <div class="col-7">
                      {{ number }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Weight LBS</label>
                    <div class="col-7">
                      {{ number }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Length</label>
                    <div class="col-7">
                      {{ number }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Width</label>
                    <div class="col-7">
                      {{ number }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Height</label>
                    <div class="col-7">
                      {{ number }}
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-12 col-form-label"> Shipping Info</label>

                    <div class="col-12">
                      <pre class="mt-3 mb-0"></pre>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-form-label"> Marketing text</label>

                    <div class="col-12">
                      <pre class="mt-3 mb-0"></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-header">
              <div class="card-title">
                Entering content here will override data from Parent Product
              </div>
            </div>
            <div class="card-body px-3">
              <div class="form-group row">
                <label class="col-12 col-form-label"> Short Description</label>

                <div class="col-12">
                  <span v-html="short_description"> </span>

                  <pre class="mt-3 mb-0"></pre>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-form-label"> Long Description</label>

                <div class="col-12">
                  <span v-html="long_description"> </span>

                  <pre class="mt-3 mb-0"></pre>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="card card-custom mt-5">
              <div class="card-header">
                <div class="card-title">
                  Attribute Set Options
                  <small>** Attrtribute Set NAME here** </small>
                </div>
              </div>

              <div class="card-body">
                <template v-if="attribute_set == 'clothing'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Style #</label>
                        <div class="col-7">
                          {{ clothing_style }}
                        </div>
                      </div>
                      <!-- <div class="form-group row">
                            <label  class="col-5 col-form-label">Season</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="Season"/>
                            </div>
                    </div>

                    <div class="form-group row">
                            <label  class="col-5 col-form-label">Size</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="Price"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Color</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="url"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Gender</label>
                            <div class="col-7">
                                <input class="form-control" type="number"  id="number"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Age Group</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="age"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Silhouette</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="Price"/>
                            </div>
                    </div>
    <div class="form-group row">
                            <label  class="col-5 col-form-label">Label Type</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="label"/>
                            </div>
                    </div>
    <div class="form-group row">
                            <label  class="col-5 col-form-label">Material</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="material"/>
                            </div>
                    </div>
    <div class="form-group row">
                            <label  class="col-5 col-form-label">Collection</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="collection"/>
                            </div>
                    </div> -->
                    </div>
                  </div>
                </template>
                <template v-if="attribute_set == 'demo1'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Demo 1</label>
                        <div class="col-7">
                          {{ demo1 }}
                          <input
                            v-model="demo1"
                            class="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="attribute_set == 'demo2'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Demo 2</label>
                        <div class="col-7">
                          {{ demo2 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

import { validationMixin } from "vuelidate";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },
  mixins: [validationMixin],
  data: () => ({
    clothing_style: "",
    demo1: "",
    demo2: "",
    attribute_set: "",
    main_image: null,
    additional_image: null,
    additional_images: [],
    main_image_url: "",
    main_image_name: "",
    main_image_id: "",
    name: "",
    product_name: "",
    product_id: "",
    sku_id: "",
    created_at: "",
    upc: "",
    upc2: "",
    isbn: "",
    status: "new",
    item_options: [
      { value: "new", text: "New" },
      { value: "pending", text: "Pending" },
      { value: "discontinued", text: "Discontinued" },
      { value: "active", text: "Active" },
    ],
    short_description: "",
    long_description: "",

    number: "",
    url: "",
    price: "",
    vimeo: "",
    youtube: "",
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",

    selected: null,

    chips: ["Fall2020", "Shirts", "mens camo"],

    editor: ClassicEditor,

    editorConfig: {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "undo",
          "redo",
        ],
      },
    },
    options: [
      { value: null, text: "New" },
      { value: "draft", text: "Draft" },
      { value: "awaiting", text: "Awaiting Approval" },
      { value: "active", text: "Active " },
      { value: "disco", text: "Discontinued" },
      { value: "eol", text: "End of Life" },
    ],
    logic: ["Active", "Pending", "Discontinued", "NOT Discontinued"],

    dialog: false,
    dialog2: false,
  }),
  created() {
    this.getItemById({
      id: this.$route.params.id,
    }).then((res) => {
      this.name = res.data.Name;
      this.attribute_set = res.data.product.attribute_set;
      this.product_name = res.data.product.name;
      this.product_id = res.data.product.id;
      this.sku_id = res.data.sku_id;
      this.created_at = res.data.created_at;
      this.status = res.data.status;
      this.upc = res.data.upc;
      this.upc2 = res.data.upc2;
      this.isbn = res.data.isbn;
      if (res.data.short_description == "") {
        this.short_description = res.data.product.short_description;
      } else {
        this.short_description = res.data.short_description;
      }
      if (res.data.long_description == "") {
        this.long_description = res.data.product.long_description;
      } else {
        this.long_description = res.data.long_description;
      }

      if (res.data.clothing_style == "") {
        this.clothing_style = res.data.product.clothing_style;
      } else {
        this.clothing_style = res.data.clothing_style;
      }
      if (res.data.demo1 == "") {
        this.demo1 = res.data.product.demo1;
      } else {
        this.demo1 = res.data.demo1;
      }
      if (res.data.demo2 == "") {
        this.demo2 = res.data.product.demo2;
      } else {
        this.demo2 = res.data.demo2;
      }
      this.youtube = res.data.youtube;
      this.vimeo = res.data.vimeo;
      if (res.data.main_image != null) {
        this.main_image_id = res.data.main_image.id;
        this.main_image_url = res.data.main_image.url;
        this.main_image_name =
          res.data.main_image.name + res.data.main_image.ext;
      }
      if (res.data.additional_image != null) {
        this.additional_images = res.data.additional_image;
      }
    });
  },
  methods: {
    ...mapActions([
      "getItemById",
      "updateItem",
      "updateItemAdditionalImg",
      "deleteFile",
    ]),
    addimageUpload() {
      if (this.additional_image != null) {
        this.updateItemAdditionalImg({
          id: this.$route.params.id,
          additional_image: this.additional_image,
        }).then(() => {
          this.refreshAdditionalImg();
        });
      }
    },
    refreshAdditionalImg() {
      this.getItemById({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data.additional_image != null) {
          this.additional_images = res.data.additional_image;
        }
      });
    },
    removeImg(id) {
      if (id != "" && id != undefined) {
        this.$bvModal
          .msgBoxConfirm("Are you sure?", {
            title: "Confirm",
            size: "sm",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.deleteFile({
                id: id,
              }).then(() => {
                this.refreshAdditionalImg();
              });
            }
          });
      }
    },
    imageChange(e) {
      let target = e.target || e.srcElement;
      let files = target.files;
      let fr = new FileReader();
      fr.onload = function() {
        document.getElementById("image_cover").src = fr.result;
      };
      fr.readAsDataURL(files[0]);
    },
    imageDelete() {
      this.main_image = "";
      this.main_image_name = "";
      this.main_image_url = "";
    },
    async submit(e) {
      e.preventDefault();

      if (this.$route.params.id != "" && this.$route.params.id != undefined) {
        if (
          this.main_image == "" &&
          this.main_image_id != "" &&
          this.main_image_id != undefined
        ) {
          this.deleteFile({
            id: this.main_image_id,
          });
        }
        this.updateItem({
          id: this.$route.params.id,
          Name: this.name,
          sku_id: this.sku_id,
          status: this.status,
          upc: this.upc,
          upc2: this.upc2,
          isbn: this.isbn,
          main_image: this.main_image,
          youtube: this.youtube,
          vimeo: this.vimeo,
          short_description: this.short_description,
          long_description: this.long_description,
          clothing_style: this.clothing_style,
          demo1: this.demo1,
          demo2: this.demo2,
        }).then(() => {
          Swal.fire({
            title: "",
            text: "The item has been successfully updated!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
