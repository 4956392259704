import { render, staticRenderFns } from "./Item-view.vue?vue&type=template&id=392daf0a&scoped=true&"
import script from "./Item-view.vue?vue&type=script&lang=js&"
export * from "./Item-view.vue?vue&type=script&lang=js&"
import style0 from "./Item-view.vue?vue&type=style&index=0&id=392daf0a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392daf0a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VIcon,VImg,VParallax,VRow,VSpacer,VTab,VTabItem,VTabs})
